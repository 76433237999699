import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 284.929 284.929"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m282.082 76.511-14.274-14.273q-2.854-2.858-6.57-2.856-3.708-.002-6.563 2.856L142.466 174.441 30.262 62.241q-2.855-2.858-6.567-2.856-3.713-.002-6.567 2.856L2.856 76.515Q-.001 79.369 0 83.082q0 3.71 2.856 6.565l133.043 133.046q2.854 2.854 6.567 2.854c3.713 0 4.661-.951 6.562-2.854L282.082 89.647c1.902-1.903 2.847-4.093 2.847-6.565 0-2.475-.945-4.665-2.847-6.571" }, null, -1)
  ])))
}
export default { render: render }